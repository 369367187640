<template>
  <div class="personalBox">
    <div class="content">
      <div class="personal-left">
        <div class="userInfo">
          <div class="left">
            <img
              :src="
                subUserInfo?.headImage
                  ? subUserInfo.headImage
                  : require('../../../assets/images/userL.png')
              "
              class="headImg"
              alt=""
            />
            <p class="userName">
              {{ subUserInfo?.nickname || subUserInfo?.name }}
            </p>
          </div>
          <div class="right">
            <div class="item">
              <p class="num" v-if="totalLearnTime > 0">
                {{
                  totalLearnTime / 3600 >= 1
                    ? (totalLearnTime / 3600).toFixed(2)
                    : (totalLearnTime / 60).toFixed(2)
                }}
              </p>
              <p class="num" v-else>0</p>
              <p class="label">
                学习时长{{ totalLearnTime / 3600 >= 1 ? "h" : "min" }}
              </p>
            </div>
            <div class="item">
              <p class="num">{{ studyStatistics?.credit || 0 }}</p>
              <p class="label">学分</p>
            </div>
            <div class="item">
              <p class="num">{{ studyStatistics?.finishCourseCount || 0 }}</p>
              <p class="label">完成课程</p>
            </div>
            <div class="item">
              <p class="num">{{ studyStatistics?.examCount || 0 }}</p>
              <p class="label">考试次数</p>
            </div>
          </div>
        </div>
        <div class="userLearnInfo">
          <div class="tabs">
            <p
              class="tabItem"
              @click="handleTab(item)"
              :class="{ tabActive: tabsIndex === item.text }"
              v-for="item in tabsList"
              :key="item.text"
              v-show="item.isShow"
            >
              {{ item.text }}
            </p>
          </div>
          <div class="contentList" v-if="tabsIndex == '学习记录'">
            <learnRecordList />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的任务'">
            <studyTask class="padding24" />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的考试'">
            <examList />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的证书'">
            <certificateList @changeTab="changeTab" />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的订单'">
            <myOrder />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的学分'">
            <myCredit />
          </div>
          <div class="contentList" v-if="tabsIndex == '我的资料'">
            <myProfile @changeTab="changeTab" />
          </div>
          <div class="contentList" v-if="tabsIndex == '账号安全'">
            <accountSafe />
          </div>
          <div class="contentList" v-if="tabsIndex == '身份认证'">
            <identify />
          </div>
          <div class="contentList" v-if="tabsIndex == '关于我们'">
            <AboutUs />
          </div>
          <div class="contentList" v-if="tabsIndex == '审批管理'">
            <approvalManagement
              @approvalJump="approvalJump"
              :current-id="approvalCurrentId"
              :homeworkNum="homeworkNum"
            />
          </div>
        </div>
      </div>
      <div class="personal-right">
        <div class="Ranking">
          <div class="rankingTab1">
            <p class="tab1Item">排行榜</p>
          </div>
          <div class="rankingTab2">
            <p
              class="tab2Item"
              @click="handleRankingTab1(index)"
              :class="{ tabActive: rankingTab2Index === index }"
              v-for="(item, index) in rankingTab2"
              :key="item"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div class="paiming-label">
          <span>我的排名</span>
        </div>
        <div class="rankingList">
          <!-- <div class="rankingItem" v-for="item in rankingList" :key="item.id">
              <img :src="item.headImage ? item.headImage : require('../../../assets/images/userL.png')" class="headImg" alt="">
              <p class="userName">{{ item.name }}</p>
              <p class="currentUserRank">{{ item.rank }}</p>
            </div> -->
          <div class="rankingItem">
            <img
              :src="
                subUserInfo?.headImage
                  ? subUserInfo.headImage
                  : require('../../../assets/images/userL.png')
              "
              class="headImg"
              alt=""
            />
            <p class="userName">{{ subUserInfo?.name || subUserInfo?.nickname }}</p>
            <p class="currentUserRank">{{ currentUserRank || "未上榜" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import learnRecordList from "./learnRecordList.vue";
import examList from "../../../../node_modules/ss-pc/src/modules/learn/page/examList.vue";
import accountSafe from "./accountSafe.vue";
import identify from "./identify.vue";
import certificateList from "./certificateList.vue";
import myProfile from "./myProfile.vue";
import myCredit from "./myCredit.vue";
import AboutUs from "./AboutUs.vue";
import myOrder from "./myOrder.vue";
import approvalManagement from "./approvalManagement.vue";
import studyTask from "../../../../node_modules/ss-pc/src/modules/learn/page/studyTask.vue";

export default {
  components: {
    learnRecordList,
    accountSafe,
    identify,
    examList,
    certificateList,
    myProfile,
    myCredit,
    myOrder,
    AboutUs,
    approvalManagement,
    studyTask,
  },
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo;
    },
  },
  data() {
    return {
      rankingList: [],
      rankingTab2Index: 0,
      rankingTab2: ["学时", "学分", "课程"],
      tabsIndex: "学习记录",
      tabsList: [
        {
          text: "学习记录",
          isShow: true,
          id: 1,
        },
        {
          text: "我的任务",
          isShow: true,
          id: 10,
        },
        {
          text: "我的考试",
          isShow: true,
          id: 2,
        },
        {
          text: "我的证书",
          isShow: true,
          id: 3,
        },
        {
          text: "我的订单",
          isShow: true,
          id: 11,
        },
        {
          text: "我的学分",
          isShow: true,
          id: 4,
        },
        {
          text: "我的资料",
          isShow: true,
          id: 5,
        },
        {
          text: "账号安全",
          isShow: true,
          id: 6,
        },
        // {
        //   text: "身份认证",
        //   isShow: true,
        //   id: 7,
        // },
        {
          text: "审批管理",
          isShow: false,
          id: 8,
        },
        {
          text: "关于我们",
          isShow: false,
          id: 9,
        },
      ],
      currentUserRank: 0,
      studyStatistics: {},
      totalLearnTime: 0,
      homeworkNum: 0,
      approvalCurrentId: 0, //审批管理
    };
  },
  created() {
    this.myReadOver();
    this.getUserTopCount(1);
    this.myStudyStatistics();
    this.getAllLearnedData();
  },
  methods: {
    // 审批管理 实操作业审批数量
    myReadOver() {
      this.$api.home.myReadOver().then((res) => {
        if (res.data) {
          const obj = this.tabsList.find((item) => {
            return item.text === "审批管理";
          });
          if (res.data.teacher) {
            obj.isShow = true;
          } else {
            obj.isShow = false;
          }
          this.homeworkNum = res.data.homeworkNum || 0;
        }
      });
    },
    // 获取学习时长
    getAllLearnedData() {
      this.$api.learn.getAllLearnedData().then((res) => {
        this.totalLearnTime = res.data.totalLearnTime || 0;
      });
    },
    myStudyStatistics() {
      const params = {
        hasCredit: true, //是否包括学分
        hasExamCount: true, //是否包含考试数量
        hasFinishCourseCount: true, //是否包含课程完成数量
      };
      this.$api.home.myStudyStatistics(params).then((res) => {
        this.studyStatistics = res.data;
      });
    },
    // 排行榜
    getUserTopCount(dataType) {
      const params = {
        pageNum: 1,
        pageSize: 10,
      };
      const data = {
        dataType, // 1:学时 2:学分 3:课程学习（学习完成数）
      };
      this.$api.home.getUserTopCount({ params, data }).then((res) => {
        this.currentUserRank = res.data?.currentUserRank || 0;
        // this.rankingList = res.data?.dataList || [];
      });
    },
    approvalJump(id) {
      this.approvalCurrentId = id;
    },
    handleTab(item) {
      this.tabsIndex = item.text;
      if (item.text === "审批管理") {
        this.myReadOver();
        this.approvalCurrentId = 0;
      }
    },
    // 子组件修改tab
    changeTab(val) {
      this.tabsIndex = val;
    },
    handleRankingTab1(index) {
      this.rankingTab2Index = index;
      this.getUserTopCount(index + 1);
    },
  },
};
</script>

<style lang="scss" src="../asset/css/index.scss" scoped></style>
