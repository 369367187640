<template>
  <div class="myCredit">
    <div class="learnTab">
      <p
        class="tabItem"
        v-for="item in learnTab"
        :key="item.key"
      >
        {{ item.value }} <span>{{ item.num }} </span>学分
      </p>
    </div>
    <div class="item" v-for="item in creditList" :key="item.id">
      <div class="item-left">
        <img src="@/assets/images/taskbg.png" class="cover" alt="" v-if="item.type === 3"/>
        <img :src="item.picUrl" class="cover" alt=""  v-else/>
        <wd-tag :item="item"></wd-tag>
      </div>
      <div class="content">
        <p class="name">{{ item.name }}</p>
        <p class="num">学分：{{ item.credit }}</p>
        <p class="time">获得时间：{{ item.receiveTime }}</p>
      </div>
      <div class="button">
        <el-button type="primary" @click="goDetail(item)">去复习</el-button>
      </div>
    </div>
    <article v-if="noData" class="ListEmptyData">
      <img src="../../../assets/images/emptyData.png" alt />
      <p>暂无数据</p>
    </article>
    <p class="loadingmore" v-if="loading">加载中...</p>
    <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      // 0全部，1今日，2本周 3本月
      learnTabIndex: 0,
      learnTab: [
        { key: 0, value: "全部", num: 0 },
        { key: 1, value: "今日获得", num: 0 },
        { key: 2, value: "本周获得", num: 0 },
        { key: 3, value: "本月获得", num: 0 },
      ],
      creditList: [],
      paging: {
        params: {
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
      },
      loading: false,
      noData: null,
    };
  },
  computed: {
    noMore() {
      return (
        this.creditList.length === this.paging.total && this.paging.total !== 0
      );
    },
  },
  created() {
    this.getMyCreditList();
    this.getMyCreditStatistics();
  },
  mounted() {
    $(window).scroll(() => {
      //判断是否滑动到页面底部
      if (
        Math.round($(window).scrollTop()) ===
        $(document).height() - $(window).height()
      ) {
        // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
        // console.log('页面底部')

        setTimeout(this.reachBottom(), 1000);
      }
    });
  },
  methods: {
    goDetail({ taskId, type }) {
      if (type === 3) {
        this.$router.push({
          path: "/learn/task/detail",
          query: {
            id: taskId,
          },
        });
      } else {
        this.$router.push({
          path: "/learn/course/detail",
          query: {
            id: taskId,
            type:type
          },
        });
      }
    },
    handleTab(item) {
      this.learnTabIndex = item.key;
      this.paging.total = 0;
      this.paging.params.pageNum = 1;
      this.creditList = [];
      this.getMyCreditList();
    },
    getMyCreditStatistics() {
      this.$api.learn.getMyCreditStatistics({}).then((res) => {
        if (res.data) {
          this.learnTab[0].num = res.data.allNum || 0;
          this.learnTab[1].num = res.data.todayNum || 0;
          this.learnTab[2].num = res.data.weekNum || 0;
          this.learnTab[3].num = res.data.monthNum || 0;
        }
      });
    },
    reachBottom() {
      if (
        this.paging.total >
        this.paging.params.pageNum * this.paging.params.pageSize
      ) {
        this.paging.params.pageNum = this.paging.params.pageNum + 1;
        this.loading = true;
        this.getMyCreditList("reachBottom");
      } else {
        this.loading = false;
      }
    },
    getMyCreditList() {
      const params = {
        ...this.paging.params,
        status: this.learnTabIndex,
      };
      this.$api.learn.getMyCreditList({ params }).then((res) => {
        if (res.data) {
          if (res.data.list && res.data.list.length) {
            this.noData = false;
          } else {
            this.noData = true;
          }
          if (this.paging.params.pageNum > 1) {
            this.creditList = this.creditList.concat(res.data.list);
          } else {
            this.creditList = res.data.list || [];
          }
          this.paging.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.item{
  position: relative;
  .item-left{
    position: relative;
  }
}
.learnTab {
  padding: 0 40px 25px;
  display: flex;
  .tabItem {
    font-size: 16px;
    font-family: PingFang-SC-Light, PingFang-SC;
    font-weight: 300;
    color: #333;
    line-height: 22px;
    margin-right: 46px;
    &.tabActive {
      color: $WDPrimaryColor;
      font-weight: 500;
    }
    span{
      color:$WDPrimaryColor;
      font-weight: 550;
      font-size: 18px;
    }
  }
}

.item {
  margin-bottom: 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px 32px;
  display: flex;
  .cover {
    width: 213px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }
  .content {
    margin-left: 24px;
    .name {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333;
      line-height: 25px;
      margin-bottom: 8px;
    }
    .num {
      width: 108px;
      height: 22px;
      background-image: url("../../../assets/images/personal/credit-num-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-left: 24px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #d26300;
      line-height: 22px;
      margin-bottom: 25px;
      box-sizing: border-box;
    }
    .time {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 20px;
    }
  }
  .button {
    margin-left: auto;
    align-items: flex-end;
    position: relative;
    top: 76px;
    ::v-deep {
      .el-button {
        width: 148px;
        height: 44px;
        border-radius: 22px;
      }
    }
  }
}
</style>
